import { useState } from "react"
import Chat from "./Chat"
import Login from "./Login"

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
  const betterSetUser = data => {
    setUser(data)
    localStorage.setItem('user', JSON.stringify(data))
  }

  return user
    ? <Chat user={user} setUser={betterSetUser} />
    : <Login setUser={betterSetUser} />
}

export default App
