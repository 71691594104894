import { useState } from "react"
import Loading from "./Loading"
import "./Login.css"

function Login({ setUser }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [status, setStatus] = useState(null)

  const handleSubmit = async e => {
    e.preventDefault()
    setStatus('loading')
    const res = await fetch('https://photochat.anxoso.com/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    })
    const data = await res.json()
    if (res.ok) {
      setUser(data)
    } else {
      setStatus('error')
    }
  }

  if (status === 'loading') {
    return (
      <main id="login">
        <form onSubmit={handleSubmit}>
          <Loading />
        </form>
      </main>
    )
  }

  return (
    <main id="login">
      <form onSubmit={handleSubmit}>
        <h1>Iniciar sesión</h1>
        <label>
          <span>Username:</span>
          <input value={username} onChange={e => setUsername(e.target.value)} />
        </label>
        <label>
          <span>Password:</span>
          <input name="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
        </label>
        <button>Entrar</button>
        {status === 'error' &&
          <p className="error">
            Usuario o contraseña incorrectos.
          </p>
        }
      </form>
    </main>
  )

}

export default Login
