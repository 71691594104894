import { useState } from 'react'
import useChat from './useChat'
import './Chat.css'

const api = 'https://photochat.anxoso.com'

function Chat({ user, setUser }) {
  const { username, token } = user
  const data = useChat('wss://photochat.anxoso.com/ws', token)
  const [msg, setMsg] = useState('')

  const images = data.images.slice().reverse()

  const handleFile = e => {
    const f = e.target.files[0]
    if (f) {
      const fd = new FormData()
      fd.append('image', f)
      fd.append('name', 'demo')
      fetch(api + '/image', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + token },
        body: fd
      })
        .catch(e => console.error(e))
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetch(api + '/message', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: 'demo', message: msg })
    })
    setMsg('')
  }

  return (
    <main>
      <div className="photos">
        {images.map(img =>
          <div key={img.date} className="photo">
            <img src={img.url} alt="" />
            <span>{img.name}</span>
          </div>
        )}
      </div>
      <div className="chat">
        <div className="message-list">
          <div className="message-list-inside">
            {data.messages.map(msg =>
              <div key={msg.date} className="message">
                <span className="nick">{msg.name}</span>
                <span className="text">{msg.message}</span>
              </div>
            )}
          </div>
        </div>
        <form className="footer" onSubmit={handleSubmit}>
          <input name="msg" placeholder={'@' + username + ' - Escribe...'} value={msg} onChange={e => setMsg(e.target.value)} autoComplete="off" />
          <button>📨</button>
          <label>
            <div className="button">📷</div>
            <input type="file" onChange={handleFile} accept="image/x-png,image/gif,image/jpeg" />
          </label>
          <button onClick={() => setUser(null)}>🔌</button>
        </form>
      </div>
    </main>
  )
}

export default Chat
