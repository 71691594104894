import { useEffect, useState } from "react";

// Uso:
// const { images, messages } = useChat('wss://photochat.anxoso.com/ws')

export default function useChat(url) {
  const [data, setData] = useState({ images: [], messages: [] })
  useEffect(() => {
    let ws
    const connect = () => {
      ws = new WebSocket(url, 'v1')
      ws.onopen = () => {
        // Recién conectado; limpiamos mensajes viejos
        setData({ images: [], messages: [] })
      }
      ws.onmessage = e => {
        // Procesar datos entrantes y añadir a existentes
        const upd = JSON.parse(e.data)
        setData(data => ({
          images: [...data.images, ...(upd.images || [])],
          messages: [...data.messages, ...(upd.messages || [])]
        }))
      }
      ws.onclose = () => {
        // Reconectar en 1s
        setTimeout(connect, 1000)
      }
    }
    connect()
    return () => ws.close()
  }, [url])
  return data
}
